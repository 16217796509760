import React from 'react';

const theme = {
  colors: {
    primary: '#3772FF',
    background: '#EDF4ED',
    secondary: '#618B4A',
    accent: '#301014',
    tertiary: '#51291E',
  },
  boxShadow: '0px 2px 2px 2px rgba(0,0,0,0.5)',
};

const headerStyle: React.CSSProperties = {
  fontSize: '1.5rem',
  width: '100%',
  textAlign: 'center',
  paddingTop: '1rem',
};

const contentStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
};

const imageStyle: React.CSSProperties = {
  maxWidth: '370px',
  margin: '1rem',
  objectFit: 'cover',
  objectPosition: 'right',
  backgroundColor: theme.colors.background,
  boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.5)',
};

const images: { [key: string]: string } = {
  bigTree: 'media/big_tree.jpg',
  branchWalk: 'media/branch_walk.jpg',
  craneVertical: 'media/crane_vertical.jpeg',
  cutClimb: 'media/cut_climb.jpg',
  highClimb: 'media/high_climb.jpg',
  sawCut: 'media/saw_cut.jpg',
  selfieClimb: 'media/selfie_climb.jpg',
};

const ABOUT_US_CONTENT = `
  Dwight's Tree Service LLC has been serving the NW Georgia area since 1994. Founded by Desert Storm veteran Dwight Price, 
  Dwight's tree service aims to provide safe and personalized arbor services for both residential and commercial customers.
`;

export function App() {
  return (
    <div
      style={{
        display: 'flex',
        flex: '1 0 auto',
        width: '100%',
        minHeight: '100%',
        backgroundColor: theme.colors.background,
      }}
    >
      <div style={{ display: 'flex', flexFlow: 'column nowrap', width: '100%' }}>
        <div
          style={{
            fontSize: '2rem',
            width: '100%',
            textAlign: 'center',
            padding: '1rem',
            backgroundColor: theme.colors.secondary,
            borderBottom: 'solid 2px rgba(0,0,0,0.25)',
          }}
        >{`Dwight's Tree Service`}</div>
        <div style={headerStyle}>{'About Us'}</div>
        <div id={'aboutContent'} style={contentStyle}>
          <img style={imageStyle} alt={'family'} src={'media/family.jpg'} />
          <div style={{ width: '300px', margin: '1rem' }}>{ABOUT_US_CONTENT}</div>
        </div>
        <div style={headerStyle}>{'Our Work'}</div>
        <div id={'ourWorkContent'} style={contentStyle}>
          {Object.keys(images).map((image) => (
            <img style={imageStyle} alt={image} src={images[image]} />
          ))}
        </div>
        <div style={headerStyle}>{'Contact Us'}</div>
        <div
          id={'contactContent'}
          style={{ ...contentStyle, ...{ padding: '0 0 2rem 0', flexFlow: 'column nowrap', textAlign: 'center' } }}
        >
          <div>{'Phone: 404-422-1109'}</div>
          <div>{'Email: price6338@yahoo.com'}</div>
        </div>
      </div>
    </div>
  );
}
